import { Component, Input, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { RestService } from '../../_shared/rest.service';
import { ModalController } from '@ionic/angular';
import { Router } from "@angular/router";
import { NotificationPage } from '../../notification/notification.page';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  notification: any = 0;
  reLoad: any;

  public innerWidth: any;
  public isDesktop = false;
  @Input('title') title: any;
  @Input('defaultHref') defaultHref: any;
  @Input('tools') tools: any = 'true';
  private popup = false;
  // @Input('tabs') tabs: any = null;
  constructor(
    private modalController: ModalController,
    private menuController: MenuController,
    public restService: RestService,
    public router: Router,
  ) {

    this.getNotifications();
  }

  ngOnInit() {

    this.reLoad = setInterval(() => {
      console.log('*** Set Interval ***');
      this.getNotifications();
    }, 60000);
    this.resize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
  resize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1023) {
      this.isDesktop = true;
      this.closePopup();
    } else {
      this.isDesktop = false;
    }
  }

  ngOnDestroy() {
    clearInterval(this.reLoad);
  }
  ionViewWillEnter() {
  }
  getNotifications() {
    let user = JSON.parse(localStorage.getItem('_cap_user'));
    if (user) {
      this.restService.get('notifications/not-readed').subscribe(data => {
        this.notification = data['not-readed'];
      },
      (err) => {
        console.log(err);
      });
    }
  }

  async popupNotification() {
    this.menuController.close();
    if (this.isDesktop == true) {
      if (this.popup == false) {
        this.popup = true;
        const modal = await this.modalController.create({
          component: NotificationPage,
          cssClass: 'side-modal',
          componentProps: {
            popup: true
          }
        });
        modal.onDidDismiss().then(data => {
          this.popup = false;

        });
    
        modal.present();
      } else {
        this.popup = false;
        const modal = await this.modalController.getTop();
        modal.dismiss();
        const modal2 = await this.modalController.getTop();
        modal.dismiss();
        const modal3 = await this.modalController.getTop();
        modal3.dismiss();
      }
    } else {
      this.router.navigate(['/notificaciones']);
    }
  }
  async closePopup() {
    if (this.popup == true) {
      this.popup = false;
      const modal = await this.modalController.getTop();
      modal.dismiss();
      const modal2 = await this.modalController.getTop();
      modal.dismiss();
      const modal3 = await this.modalController.getTop();
      modal3.dismiss();
    }
  }
}
