import { Component, Input, OnInit, ViewEncapsulation, Injector, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { EventEmitterService } from '../../_shared/event-emitter.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabsComponent implements OnInit {

  @Input('tabs') tabs: any = [];
  @Input('full') full: any = 'false';
  @ViewChild('slides', { static: false }) slides: IonSlides;

  slideOpts = {
    initialSlide: 0,
    watchOverflow: false,
    slidesPerView: 'auto'
  };
  public selectedCategory = 0;

  constructor(
    public injector: Injector,
    private eventEmitterService: EventEmitterService
  ) {

    this.eventEmitterService.tabsChangeSubsVar = this.eventEmitterService.
      tabsChange.subscribe((data) => {
        let currentIndex = data.currentIndex;
        this.slides.slideTo(currentIndex);
        this.selectedCategory = currentIndex;


      });

  }

  ngOnInit() {
    this.slideOpts = {
      initialSlide: 0,
      watchOverflow: (this.full != 'false') ? true : false,
      slidesPerView: 'auto'
    };
  }
  ionViewWillEnter() {
  }

  // ...

  private initializeCategories(): void {
    this.selectedCategory = 0;
  }

  public filterData(index): void {
    // Handle what to do when a category is selected
    this.slides.slideTo(index);
    this.eventEmitterService.publish('tabs', { slug: this.tabs[index].slug, currentIndex: index });
    this.selectedCategory = index;

  }

  // Method executed when the slides are changed
  public async slideChanged() {
    let currentIndex = await this.slides.getActiveIndex();
    // this.selectedCategory = (this.tabs[currentIndex-1])?this.tabs[currentIndex-1].id:-1;
    // this.showLeftButton = currentIndex !== 0;
    // this.showRightButton = currentIndex !== Math.ceil(this.slides.length() / 3);

  }

  // Method that shows the next slide
  public slideNext(): void {
    //this.slides.slideNext();
  }

  // Method that shows the previous slide
  public slidePrev(): void {
    //this.slides.slidePrev();
  }
}
