import { Component, OnInit, ViewChild, Input, ElementRef, ViewEncapsulation} from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageModalPage implements OnInit {
  @ViewChild(IonSlides) ionSlides: IonSlides;

  @Input('gallery') gallery: any;
  @Input('img') img: any;

  sliderOpts = {
    zoom: true
  };

  constructor(private modalController: ModalController) { }

  ngOnInit() { 

  }

  ionViewDidEnter() {
    this.ionSlides.update();
    this.ionSlides.slideTo(this.img);
    setTimeout(() => {
      this.zoom(true);
    }, 500);
  }

  async zoom(zoomIn: boolean) {
    const slider = await this.ionSlides.getSwiper();
    const zoom = slider.zoom;
    zoomIn ? zoom.in() : zoom.out();
  }

  close() {
    this.modalController.dismiss();
  }

}