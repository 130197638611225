import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {

  public innerWidth: any;
  isDesktop = false;

  comparadorLink = '/comparador';
  constructor() { }

  ngOnInit() {
    this.resize();
}
@HostListener('window:resize', ['$event'])
onResize(event) {
  this.resize();
}
resize() {
  this.innerWidth = window.innerWidth;
  if (this.innerWidth > 1023) {
    this.comparadorLink = '/comparador/filtro';
    this.isDesktop = true;
  } else {
    this.isDesktop = false;
    this.comparadorLink = '/comparador';
  }
}
}
