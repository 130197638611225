import { Component, OnInit, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { EventEmitterService } from './_shared/event-emitter.service';
import { AuthenticationService } from './_shared/authentication.service';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Nuestros productos',
      url: '/productos',
      icon: 'icon-productos',
    },
    {
      title: 'Comparador dinámico',
      url: '/comparador',
      icon: 'icon-comparador',
    },
    {
      title: 'Formaciones',
      url: '/formaciones',
      icon: 'icon-formaciones',
    },
    {
      title: 'Comunicaciones',
      url: '/comunicaciones',
      icon: 'icon-comunicaciones',
    },
    {
      title: 'Servicios',
      url: '/servicios',
      icon: 'icon-servicios',
    },
    {
      title: 'My Renault',
      url: '/my-renault',
      icon: 'icon-renault',
    },
    // {
    //   title: 'Trash',
    //   url: '/folder/Trash',
    //   icon: 'trash'
    // },
    // {
    //   title: 'Spam',
    //   url: '/folder/Spam',
    //   icon: 'warning'
    // }
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public menu = false;
  public user = { name: '', email: '' };
  public innerWidth: any;
  isDesktop = false;

  constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar, public router: Router, private afMessaging: AngularFireMessaging, private eventEmitterService: EventEmitterService, private authenticationService: AuthenticationService) {
    this.initializeApp();

    let user = JSON.parse(localStorage.getItem('_cap_user'));
    if (user) {
      this.menu = true;
      this.user = user.user;
    }
    this.eventEmitterService.loginChangeSubsVar = this.eventEmitterService.loginChange.subscribe((data) => {
      let user = JSON.parse(localStorage.getItem('_cap_user'));
      if (user) {
        this.menu = true;
        this.user = user.user;
      } else {
        this.menu = false;
        this.user = { name: '', email: '' };
      }
    });
    this.resize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
  resize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1023) {
      this.appPages[1]['url'] = '/comparador/filtro';
      this.isDesktop = true;
    } else {
      this.appPages[1]['url'] = '/comparador';
      this.isDesktop = false;
    }
  }
  logout() {
    this.authenticationService.logout();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.requestPushNotificationsPermission();
    });
  }
  // Trigger the push setup
  requestPushNotificationsPermission() {
    // requesting permission
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => {
          // USER-REQUESTED-TOKEN
          localStorage.setItem('_cap_notification_token', token);
          console.log('Permission granted! Save to the server!', token);
        },
        (error) => {
          console.error(error);
        }
      );
    if (navigator && navigator.serviceWorker) navigator.serviceWorker.addEventListener('message', this.onReceiveMsg.bind(this));
  }

  onReceiveMsg(event) {
    if (event.data && event.data['firebase-messaging-msg-data']) {
      const { data } = event.data['firebase-messaging-msg-data'];
      console.log({ data });
    }
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex((page) => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
