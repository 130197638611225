import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    public angularFireAnalytics: AngularFireAnalytics
  ) {
   // this.angularFireAnalytics.setAnalyticsCollectionEnabled(false);
  }


  trackView(title) {
    this.angularFireAnalytics.setCurrentScreen(title)
  }

  async setUserId(uid) {
    this.angularFireAnalytics.setUserId(uid);
  }
  setUserProperty(key, value) {
    this.angularFireAnalytics.setUserProperties({ name: key, value: value });
  }

  logEvent(event, data = {}) {
    this.angularFireAnalytics.logEvent(event,data);
  }
}
