import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ServiceWorkerModule } from "@angular/service-worker";

import { environment } from "../environments/environment";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import {
	AngularFireAnalyticsModule,
	ScreenTrackingService,
} from "@angular/fire/analytics";
import { AngularFireMessagingModule } from "@angular/fire/messaging";

import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { ComponentsModule } from "./_components/components.module";

import { AuthenticationService } from "./_shared/authentication.service";
import { AuthenticationGuardService } from "./_shared/authentication-guard.service";
import { NativeStorage } from "@ionic-native/native-storage/ngx";

import { FlatpickrModule } from "angularx-flatpickr";

@NgModule({
	declarations: [AppComponent],
	imports: [
		ComponentsModule,
		BrowserModule,
		FlatpickrModule.forRoot(),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireMessagingModule,
		AngularFireAnalyticsModule,
		AngularFireAuthModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		ServiceWorkerModule.register("combined-sw.js", {
			enabled: environment.production,
		}),
	],
	providers: [
		{ provide: ScreenTrackingService, useValue: {} },
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		AuthenticationService,
		AuthenticationGuardService,
		NativeStorage,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
