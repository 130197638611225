import { Component, OnInit, ViewChild, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '../_shared/loader.service';
import { AnalyticsService } from '../_shared/analytics.service';
import { RestService } from '../_shared/rest.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { DetailPage } from './detail/detail.page';

export function flatpickrFactory() {
  flatpickr.localize(Spanish);
  return flatpickr;
}
@Component({
  selector: 'app-notification',
  templateUrl: './notification.page.html',
  styleUrls: ['./notification.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationPage implements OnInit {
  @ViewChild(IonInfiniteScroll, { static: true }) infiniteScroll: IonInfiniteScroll;

  public innerWidth: any;
  public isDesktop = false;
  public loader = true;
  public items: any = [];
  public selectedDate: any = '';

  public page: any = 1;
  public model: any = null;
  public version: any = null;
  public slug: any = null;
  public brand: any = null;
  public vehicle: any = null;
  public vehicle_version: any = null;
  public defaultHref = '/';
  @Input('popup') popup: any = false;
  public query = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    public restService: RestService,
    private analyticsService: AnalyticsService,
    public router: Router,
    private modalController: ModalController,
    private ionLoader: LoaderService) {
    // let today = new Date();
    // this.selectedDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);


    this.model = this.activatedRoute.snapshot.paramMap.get('model');
    this.version = this.activatedRoute.snapshot.paramMap.get('version');
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
    this.brand = this.activatedRoute.snapshot.paramMap.get('brand');
    if (this.slug) {
      this.defaultHref = this.defaultHref + '/comparador/filtro/' + this.brand + '/' + this.model + '/' + this.version + '/' + this.slug;
    } else if (this.version) {
      this.defaultHref = this.defaultHref + '/productos/' + this.model + '/' + this.version;
    } else if (this.model) {
      this.defaultHref = this.defaultHref + '/productos/' + this.model;
    } else {
      this.defaultHref = this.defaultHref + '/home';
    }
    if (this.slug) {
      this.version = this.slug;
    }

  }

  ionViewWillEnter() {
    let page = '';
    if (this.slug) {
      page = 'comparador-filtro-' + this.brand + '-' + this.model + '-' + this.version + '-' + this.slug;
    } else if (this.version) {
      page = 'productos-' + this.model + '-' + this.version;
    } else if (this.model) {
      page = 'productos' + this.model;
    } else {
      page = '';
    }
    this.analyticsService.trackView(page + '-notificaciones');
  }
  ngOnInit() {
    this.getItems('top');
    this.resize();
    flatpickrFactory();

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
  resize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1023) {
      this.isDesktop = true;
      if (this.popup != true) this.router.navigate([this.defaultHref]);
    } else {
      this.isDesktop = false;
    }
  }
  filter(event) {
    let q = event.detail.value.toLowerCase();
    if (q != this.query) {
      this.loader = true;
      this.page = 1;
      this.items = [];
      this.infiniteScroll.disabled = false;
      this.query = q;
      this.getItems('top');
    }
  }

  dateSelected(event) {
    this.selectedDate = event.dateString
    this.loader = true;
    this.page = 1;
    this.items = [];
    this.infiniteScroll.disabled = false;
    this.getItems('top');
  }

  infinite(event) {
    setTimeout(() => {
      console.log('Done');
      event.target.complete();
      this.page++;
      this.getItems('bottom');

    }, 500);
  }

  private getItems(type) {


    if (this.popup != true) this.ionLoader.showLoader();
    let path;
    if (this.version != null) {
      path = 'notifications?version=' + this.version + '&';
    } else if (this.model != null) {
      path = 'notifications?model=' + this.model + '&';
    } else {
      path = 'notifications?';
    }
    //    if (this.page > 1) this.analyticsService.trackView('News_' + this.page);

    var a = this;
    this.restService.get(path + 'search=' + this.query + '&page=' + this.page + '&date=' + this.selectedDate).subscribe(data => {
      let items;
      
      if (this.model || this.version) {
        this.vehicle = data['model']
        this.vehicle_version = data['version']
        if (this.ObjectLength(data['notifications']) > 0) {
          items = data['notifications']
          a.items = { ...a.items, ...items };
        } else {
          this.infiniteScroll.disabled = true;
        }
      } else {
        if (this.ObjectLength(data) > 0) {
          items = data['notifications']
          a.items = { ...a.items, ...items };
        } else {
          this.infiniteScroll.disabled = true;
        }
      }
      if (this.popup != true) this.ionLoader.hideLoader();
      this.loader = false;
    }
      ,
      (err) => {
        console.log(err);
        if (this.popup != true) this.ionLoader.hideLoader();
        this.loader = false;
      });
  }
  ObjectLength(object) {
    var length = 0;
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        ++length;
      }
    }
    return length;
  };
  unsorted() { 
    return 0
  }

  async popupNotification(notificaction) {
    if (this.isDesktop == true) {
      const modal = await this.modalController.create({
        component: DetailPage,
        cssClass: 'side-modal',
        componentProps: {
          popup: true,
          slugg: notificaction.slug
        }
      });
      modal.present();
    } else {
      this.router.navigate([notificaction.slug], { relativeTo: this.activatedRoute });
    }
  }
  open(item) {
    
    this.popupNotification(item);
    

  }
  close() {
    if (this.popup == true) {
      this.modalController.dismiss();
    }
  }

}
