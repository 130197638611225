import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { get} from "./storage.service";
import { RestService } from './rest.service';
@Injectable({
  providedIn: "root"
})
export class AuthenticationGuardService implements CanActivate {
  constructor(private router: Router, public restService: RestService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    let user= JSON.parse(localStorage.getItem('_cap_user'));

    console.log(user);
    if (!user) {
      this.router.navigate(["login"]);
      return false;
    }else{
      this.restService.refreshHeaders(user.access_token);
    }

    return true;
  }
}