import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from './_shared/authentication-guard.service';

const routes: Routes = [
	{ path: "", redirectTo: "login", pathMatch: "full" },
	{
		path: "login",
		loadChildren: () =>
			import("./auth/login/login.module").then((m) => m.LoginPageModule),
	},
	{
		path: "recuperar",
		loadChildren: () =>
			import("./auth/forgot-password/forgot-password.module").then(
				(m) => m.ForgotPasswordPageModule
			),
	},
	{
		path: "recuperar/codigo/:email",
		loadChildren: () =>
			import("./auth/validate-code/validate-code.module").then(
				(m) => m.ValidateCodePageModule
			),
	},
	{
		path: "recuperar/password/:email/:code",
		loadChildren: () =>
			import("./auth/change-password/change-password.module").then(
				(m) => m.ChangePasswordPageModule
			),
	},
	{
		path: "home",
		loadChildren: () =>
			import("./home/home.module").then((m) => m.HomePageModule),
		canActivate: [AuthenticationGuardService],
	},
	{
		path: "productos",
		loadChildren: () =>
			import("./product/product.module").then((m) => m.ProductPageModule),
		canActivate: [AuthenticationGuardService],
	},
	{
		path: "comunicaciones",
		loadChildren: () =>
			import("./comunication/comunication.module").then(
				(m) => m.ComunicationPageModule
			),
		canActivate: [AuthenticationGuardService],
	},
	{
		path: "notificaciones",
		loadChildren: () =>
			import("./notification/notification.module").then(
				(m) => m.NotificationPageModule
			),
		canActivate: [AuthenticationGuardService],
	},
	{
		path: "comparador",
		loadChildren: () =>
			import("./compare/compare.module").then((m) => m.ComparePageModule),
		canActivate: [AuthenticationGuardService],
	},
	{
		path: "formaciones",
		loadChildren: () =>
			import("./formation/formation.module").then((m) => m.FormationPageModule),
	},
	{
		path: "servicios",
		loadChildren: () =>
			import("./service/service.module").then((m) => m.ServicePageModule),
		canActivate: [AuthenticationGuardService],
	},
	{
		path: "my-renault",
		loadChildren: () =>
			import("./mirenault/mirenault.module").then(
				(m) => m.MiRenaultPageModule
			),
		canActivate: [AuthenticationGuardService],
	},
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
