import { Component, OnInit, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { ImageModalPage } from '../../_components/image-modal/image-modal.page';
import { ModalController, IonSlides } from '@ionic/angular';
import { LoaderService } from '../../_shared/loader.service';
import { RestService } from '../../_shared/rest.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";

import { AnalyticsService } from '../../_shared/analytics.service';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailPage implements OnInit {
  loader = true;

  public item: any = {};
  public innerWidth: any;
  public model: any = null;
  public version: any = null;
  public slug: any = null;
  public brand: any = null;
  public vehicle: any = null;
  public notification_slug: any = null;
  isDesktop = false;
  public defaultHref = '/';
  @Input('popup') popup: any = false;
  @Input('slugg') slugg: any = false;
  constructor(private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    public restService: RestService,
    public router: Router,
    private ionLoader: LoaderService) {

    this.notification_slug = this.activatedRoute.snapshot.paramMap.get('notification_slug');
    this.model = this.activatedRoute.snapshot.paramMap.get('model');
    this.version = this.activatedRoute.snapshot.paramMap.get('version');
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
    this.brand = this.activatedRoute.snapshot.paramMap.get('brand');
    if (this.slug) {
      this.defaultHref = this.defaultHref + '/comparador/filtro/' + this.brand + '/' + this.model + '/' + this.version + '/' + this.slug;
    } else if (this.version) {
      this.defaultHref = this.defaultHref + '/productos/' + this.model + '/' + this.version;
    } else if (this.model) {
      this.defaultHref = this.defaultHref + '/productos/' + this.model;
    } else {
      this.defaultHref = this.defaultHref;
    }
    this.defaultHref = this.defaultHref + '/notificaciones'
    if (this.slug) {
      this.version = this.slug;
    }
  }

  ionViewWillEnter() {
    if (this.popup == true) this.notification_slug = this.slugg;
    this.analyticsService.trackView('notificaciones-' + this.notification_slug);
  }
  ngOnInit() {

    if (this.popup == true) this.notification_slug = this.slugg;
    if (this.popup != true) this.ionLoader.showLoader();
    this.restService.get('general-notifications/' + this.notification_slug).subscribe(data => {
      this.item = data;
      
      if (this.popup != true) this.ionLoader.hideLoader();
      if (this.innerWidth > 1023) {
        if (this.popup != true) {
          this.popupNotification(this.item);
        }
      } 
      this.loader = false;
    },
      (err) => {
        console.log(err);
        if (this.popup != true) this.ionLoader.hideLoader();
        this.loader = false;
      });

    this.resize();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
  resize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1023) {
      this.isDesktop = true;
      if (this.popup != true) {
        this.router.navigate([this.defaultHref]);
      }
    } else {
      this.isDesktop = false;
    }
  }

  async popupNotification(notificaction) {
    if (this.isDesktop == true) {
      const modal = await this.modalController.create({
        component: DetailPage,
        cssClass: 'side-modal',
        componentProps: {
          popup: true,
          slugg: notificaction.slug
        }
      });
      modal.present();
    } else {
      this.router.navigate([notificaction.slug], { relativeTo: this.activatedRoute });
    }
  }
  async openPreview(gallery, img) {
    const modal = await this.modalController.create({
      component: ImageModalPage,
      cssClass: 'transparent-modal',
      componentProps: {
        img, gallery
      }
    });
    modal.present();
  }
  open() {

  }

  close() {
    if (this.popup == true) {
      this.modalController.dismiss();
    }
  }

}
