import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { TabsComponent } from './tabs/tabs.component';
import { ShareComponent } from './share/share.component';
import { IonicModule } from '@ionic/angular';
import { ImageModalPage } from './image-modal/image-modal.page';

@NgModule({
  declarations: [FooterComponent,HeaderComponent,ShareComponent,TabsComponent, ImageModalPage],
  imports: [
    CommonModule,RouterModule,IonicModule
  ],
  exports: [FooterComponent,HeaderComponent,ShareComponent,TabsComponent, ImageModalPage]
})
export class ComponentsModule { }
