import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { RestService } from './rest.service';
import { Platform } from '@ionic/angular';
import { get, set, remove } from "./storage.service";
import { EventEmitterService } from './event-emitter.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  userData: any;
  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public restService: RestService,
    public ngZone: NgZone,
    private eventEmitterService: EventEmitterService,
    private platform: Platform
  ) {
  }

  // Forget Password 1 step
  forgotPasswordCreateCode(email) {
    return this.restService.forgotPasswordCreateCode(email)
  }
  // Forget Password 2 step
  forgotPasswordValidateCode(email, code) {
    return this.restService.forgotPasswordValidateCode(email, code)
  }
  // Forget Password 2 step
  forgotPasswordChangePassword(email, code, password, password_confirmation) {
    return this.restService.forgotPasswordChangePassword(email, code, password, password_confirmation)
  }

  // Email verification when new user register
  /*sendVerificationMail() {
    return this.ngFireAuth.currentUser.then(u => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['/auth/verify-email']);
      })
  }*/

  // Login in with email/password
  signIn(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password)
  }


  // Login in with email/password
  logIn(token) {
    let notification_token = localStorage.getItem('_cap_notification_token');
    return this.restService.logIn(token,notification_token)
  }
  
  // Login in with email/password
  logout() {
    let notification_token = localStorage.getItem('_cap_notification_token');
    this.restService.logout(notification_token).subscribe(data => {
      this.eventEmitterService.publish('loginChange', {});
      this.router.navigate(['/login']);
    }
      ,
      (err) => {
        console.log(err);
        return false;
      });

  }


  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user.emailVerified !== false) ? true : false;
  }
}