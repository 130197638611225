import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService {    
    
  tabs = new EventEmitter();    
  tabsSubsVar: Subscription;    
    
  tabsChange = new EventEmitter();    
  tabsChangeSubsVar: Subscription;    
    
  loginChange = new EventEmitter();    
  loginChangeSubsVar: Subscription;    
    
  constructor() { }    
    
  publish(key,array) {    
    this[key].emit(array);    
  }    
}    