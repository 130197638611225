import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { User } from "./user";
import { Plugins } from '@capacitor/core';

import { get, set, remove } from "./storage.service";
const { Storage } = Plugins;

import { Router } from "@angular/router";

import { EventEmitterService } from './event-emitter.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  headers = {};
  API_URL = environment.server + '/api/';
  constructor(
    private http: HttpClient,
    private router: Router,
    private eventEmitterService: EventEmitterService,
  ) {
    let user = JSON.parse(localStorage.getItem('_cap_user'));
    if (user) {
      this.refreshHeaders(user.access_token);
    }
  }
  refreshHeaders(val) {
    this.headers = { headers: new HttpHeaders().set('Authorization', `Bearer ${val}`).set('Accept', 'application/json') }
  }
  logIn(token: String,notification_token) {
    return this.http.post(this.API_URL + 'auth/login',
      { firebase_token: token,notification_token: notification_token }
    ).pipe(
      tap(data => {
        set('user', data);
        return data;
      }),
    );
  }
  forgotPasswordCreateCode(email: String) {
    return this.http.post(this.API_URL + 'forgot-password/create-code',
      { email: email }
    ).pipe(
      tap(data => {
        return data;
      }),
    );
  }
  forgotPasswordValidateCode(email: String, code: String) {
    return this.http.post(this.API_URL + 'forgot-password/validate-code',
      { email: email, code: code }
    ).pipe(
      tap(data => {
        return data;
      }),
    );
  }
  forgotPasswordChangePassword(email: String, code: String, password: String, password_confirmation: String) {
    return this.http.post(this.API_URL + 'forgot-password/change-password',
      { email: email, code: code, password: password, password_confirmation: password_confirmation }
    ).pipe(
      tap(data => {
        return data;
      }),
    );
  }
  logout(notification_token) {
    return this.http.post(this.API_URL + 'auth/logout',
    { notification_token: notification_token }, this.headers)
      .pipe(
        tap(data => {
          localStorage.removeItem('_cap_user');
          return data;
        })
      )
  }
  user() {
    return this.http.get<User>(this.API_URL + 'auth/user', this.headers)
      .pipe(
        tap(user => {
          return user;
        })
      )
  }
  post(path, params = {}) {
    return this.http.post(this.API_URL + path, params,
      this.headers
    ).pipe(
      tap(data => {
        return data;
      }, err => { return this.error(err) }
      ),
    );
  }
  get(path) {
    return this.http.get(this.API_URL + path, this.headers
    ).pipe(
      tap(data => {
        return data;
      }, err => { return this.error(err) }
      ),
    );
  }
  error(data) {
    if (data.error['message'] == 'Unauthenticated.') {
      localStorage.removeItem('_cap_user');
      this.eventEmitterService.publish('loginChange', {});
      this.router.navigate(['/login']);
      return data;
    }
  }
}